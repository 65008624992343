<template>
  <v-dialog
    @click:outside="closeDialog"
    persistent
    no-click-animation
    v-model="show"
    class="hideScroll"
    :width="$vuetify.breakpoint.mobile ? '' : '90%'"
    height="90vh"
  >
    <v-card class="dialog-content">
      <filter-component presentation="true"></filter-component>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show"],
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
  components: {
    FilterComponent: () => import("../../views/Filter.vue"),
  },
};
</script>

<style scoped>
.v-dialog > div {
  height: 90vh;
}
@media only screen and (max-width: 1200px) {
  .v-dialog {
    width: unset;
  }
}
</style>
